<template>
  <div>
    <b-card v-if="d_showLeftPage === 'organization_list'" no-body :class="[f_calculateLeftPageClass()]" @mouseleave="d_showLeftPage = false">
      <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
        Organizasyon Listesi
        <b-button @click="d_showLeftPage = false" class="pull-right" variant="primary"><i class="fa fa-close"></i></b-button>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col sm="12">
          <b-form-checkbox-group stacked v-model="d_searchTaskListData.organization_list">
            <template v-for="(org, org_id) in d_organizationList">
              <b-row style="border-bottom: solid 1px #20a8d8;">
                <b-col sm="10">
                  <b-form-checkbox name="not_assigned" :value="org.id">
                    {{ org.name.toLocaleLowerCase('tr') }}
                  </b-form-checkbox>
                </b-col>
                <b-col sm="2">
                  <b-dropdown variant="primary" size="md" class="pull-right">
                    <template slot="button-content"> </template>
                    <b-dropdown-item @click="f_goToThisOrganization(org.id)"> Organizasyona Git </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="d_showLeftPage === 'task_type'" no-body :class="[f_calculateLeftPageClass()]" @mouseleave="d_showLeftPage = false">
      <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
        Görev Tipi
        <b-button @click="d_showLeftPage = false" class="pull-right" variant="primary"><i class="fa fa-close"></i></b-button>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col sm="12">
          <b-form-checkbox-group stacked v-model="d_searchTaskListData.task_type_list">
            <template v-for="(task_type, task_type_ind) in d_wdm17TaskTypeOptions">
              <b-form-checkbox :key="'d_wdm17TaskTypeOptions_' + task_type_ind" name="not_assigned" :value="task_type.value">
                {{ task_type.label.toLocaleLowerCase('tr') }}
              </b-form-checkbox>
            </template>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="d_showLeftPage === 'task_status'" no-body :class="[f_calculateLeftPageClass()]" @mouseleave="d_showLeftPage = false">
      <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
        Görev Durumu
        <b-button @click="d_showLeftPage = false" class="pull-right" variant="primary"><i class="fa fa-close"></i></b-button>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col sm="12">
          <b-form-checkbox-group stacked v-model="d_searchTaskListData.state_list">
            <template v-for="(status, status_ind) in d_wdm17StateOptions">
              <b-form-checkbox :key="'d_wdm17StateOptions_checkbox_' + st_ind" name="not_assigned" :value="status.value">
                {{ status.label.toLocaleLowerCase('tr') }}
              </b-form-checkbox>
            </template>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="d_showLeftPage === 'task_tree_filter'" no-body :class="[f_calculateLeftPageClass()]" @mouseleave="d_showLeftPage = false">
      <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
        Görev Ağacı Filtrelemesi
        <b-button @click="d_showLeftPage = false" class="pull-right" variant="primary"><i class="fa fa-close"></i></b-button>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col sm="12">
          <b-form-checkbox-group stacked v-model="d_searchTaskListData.tree_filter">
            <b-form-checkbox name="with_tree" value="with_tree"> Görev Ağacı Olanlar </b-form-checkbox>
            <b-form-checkbox v-if="d_searchTaskListData.tree_filter.indexOf('with_tree') !== -1" name="with_tree_main" value="with_tree_main"> Ana Görevler </b-form-checkbox>
            <b-form-checkbox v-if="d_searchTaskListData.tree_filter.indexOf('with_tree') !== -1" name="with_tree_sub" value="with_tree_sub"> Alt Görevler </b-form-checkbox>
            <b-form-checkbox name="without_tree" value="without_tree"> Görev Ağacı Olmayanlar </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="d_showLeftPage === 'task_user'" no-body :class="[f_calculateLeftPageClass()]" @mouseleave="d_showLeftPage = false">
      <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
        Görev Kullanıcıları
        <b-button @click="d_showLeftPage = false" class="pull-right" variant="primary"><i class="fa fa-close"></i></b-button>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col sm="12">
          <b-form-checkbox-group stacked v-model="d_searchTaskListData.user_type">
            <b-form-checkbox name="this" value="this"> Bana Ait</b-form-checkbox>
            <b-form-checkbox v-if="selected_organization_item && selected_organization_item.index" name="selected" value="selected"> Seçili Katman</b-form-checkbox>
            <b-form-checkbox v-if="selected_organization_item && selected_organization_item.index" name="selected_and_sub_layers" value="selected_and_sub_layers"> Seçili ve Alt Katmanlar</b-form-checkbox>
            <b-form-checkbox name="all" value="all"> Tüm Katmanlar</b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="d_showLeftPage === 'task_priority'" no-body :class="[f_calculateLeftPageClass()]" @mouseleave="d_showLeftPage = false">
      <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
        Görev Önceliği
        <b-button @click="d_showLeftPage = false" class="pull-right" variant="primary"><i class="fa fa-close"></i></b-button>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col sm="12">
          <b-form-checkbox-group stacked v-model="d_searchTaskListData.priority">
            <template v-for="(priority, priority_ind) in d_priorityList">
              <b-form-checkbox :key="'d_priorityList_' + priority_ind" :value="priority.value">
                {{ priority.label }}
              </b-form-checkbox>
            </template>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </b-card>
    <b-row :style="'height:' + (device.height - 200).toString() +  'px;'">
      <b-col cols="12">
        <div class="wisdom-left-menu">
          <img class="navbar-brand-minimized" src="img/brand/sygnet.svg" width="100%;" alt="WisdomEra">
          <img v-if="!organization_tree" src="@/icon/2209527.png" class="wisdom-left-menu-item-image" @mouseover="d_showLeftPage = 'organization_list'" />
          <img src="@/icon/2535757.png" class="wisdom-left-menu-item-image" @mouseover="d_showLeftPage = 'task_type'" />
          <img src="@/icon/824448.png" class="wisdom-left-menu-item-image" @mouseover="d_showLeftPage = 'task_priority'" />
          <img src="@/icon/9370.png" class="wisdom-left-menu-item-image" @mouseover="d_showLeftPage = 'task_user'" />
          <img src="@/icon/937437.png" class="wisdom-left-menu-item-image" @mouseover="d_showLeftPage = 'task_status'" />
          <img src="@/icon/2448909.png" class="wisdom-left-menu-item-image" @mouseover="d_showLeftPage = 'task_tree_filter'" />
        </div>
        <b-card no-body :style="f_calculateRightSideStyle()">
          <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
            <b-row>
              <b-col sm="12" md="3" :style="right_menu ? 'min-width: 250px;' : ''">
                <img src="@/icon/2751062.png" width="30px" style="border-radius: 3px;" /> Görevler Listesi
              </b-col>
              <b-col sm="12" md="3" :style="right_menu ? 'min-width: 250px;' : ''">
                <b-form-input id="search_patient" placeholder="Arama yap" type="text" style="background-color: #fff" v-model="d_searchText"></b-form-input>
              </b-col>
              <b-col sm="12" md="3" :style="right_menu ? 'min-width: 250px;' : ''">
                <span class="pull-right">Toplam Görev Sayısı: {{ d_taskListCount }}</span>
              </b-col>
              <b-col sm="12" md="3" :style="right_menu ? 'min-width: 250px;' : ''">
                <b-pagination size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="pagination" v-model="d_pagination.current" :total-rows="d_taskListCount" :per-page="d_pagination.perpage"></b-pagination>
              </b-col>
            </b-row>
          </b-card-header>
          <template v-for="(task, task_ind) in d_taskList">
            <b-row class="wisdom-task">
              <b-col sm="12" md="1" :style="f_calculateRightPageStyle()">
                <div :style="f_calculatePriorityStyle(task)"></div>
                <b-dropdown variant="secondary" size="lg" style="width: 75%; float: left;">
                  <template slot="button-content">
                    <div style="padding: 0px; margin: 0px;">No: {{ task.id }}</div>
                  </template>
                  <b-dropdown-item @click="f_showTaskPreviewModal(task)"><i class="fa fa-eye"></i> görevi görüntüle</b-dropdown-item>
                  <b-dropdown-item v-if="selected_organization_item && selected_organization_item.index && f_controlOnlyMyPermission(task, 'can_give_task') && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true)" @click="f_giveTaskToSelectedLayer('transfer', task_ind)"><i class="fa fa-star"></i> Bu görevi seçili kullanıcıya taşı (diğerlerinden siler)</b-dropdown-item>
                  <b-dropdown-item v-if="selected_organization_item && selected_organization_item.index && f_controlOnlyMyPermission(task, 'can_give_task') && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true)" @click="f_giveTaskToSelectedLayer('add', task_ind)"><i class="fa fa-star"></i> Bu görevi seçili kullanıcıya da ekle</b-dropdown-item>
                  <b-dropdown-item v-if="selected_organization_item"><i class="fa fa-edit"></i> seçili katmana görev ekle </b-dropdown-item>
                  <b-dropdown-item v-if="task.data && task.data.general && task.data.general.task_type && task.data.general.task_type.val && task.data.general.task_type.val.value === 'drug_medical_equipment' && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true)" @click="f_editBrand(task)"><i class="fa fa-edit"></i> ürün verisini düzenle</b-dropdown-item>
                  <b-dropdown-item v-if="task.data && task.data.general && task.data.general.task_type && task.data.general.task_type.val && task.data.general.task_type.val.value === 'patient_data' && task.data.general.selected_patient_task_type && task.data.general.selected_patient_task_type.val && task.data.general.selected_patient_task_type.val.value === 'wdm-record' && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true)" @click="f_uploadPatientData(task)"><i class="fa fa-edit"></i> hasta verisi yükle</b-dropdown-item>
                  <b-dropdown-item v-if="task.data && task.data.general && task.data.general.task_type && task.data.general.task_type.val && task.data.general.task_type.val.value === 'patient_data' && task.data.general.selected_patient_task_type && task.data.general.selected_patient_task_type.val && task.data.general.selected_patient_task_type.val.value === 'wdm-record' && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true)" @click="f_editPatientData(task)"><i class="fa fa-edit"></i> yüklenmiş hasta verilerini düzenle</b-dropdown-item>
                  <b-dropdown-item v-if="task.data && task.data.general && task.data.general.task_type && task.data.general.task_type.val && task.data.general.task_type.val.value === 'patient_data' && task.data.general.selected_patient_task_type && task.data.general.selected_patient_task_type.val && task.data.general.selected_patient_task_type.val.value === 'wdm-record' && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true)" @click="f_createWdm21ForTask(task)"><i class="fa fa-edit"></i> bu veri türüne ulaşılamadı bilgisi ekle</b-dropdown-item>
                  <b-dropdown-item v-if="task.data && task.data.general && task.data.general.task_tree_list && task.data.general.task_tree_list.val" @click="f_showTaskTreeList(task, task.data.general.task_tree_list.val, task_ind)"><i class="fa fa-eye"></i> görev ağacını göster</b-dropdown-item>
                  <b-dropdown-item v-if="task.user_index_list && Object.keys(task.user_index_list).length > 0" @click="f_addNewTask('wdm17', 'assigned', 'user', '', 'no', 'yes', task_ind)"><i class="fa fa-edit"></i> subtask ekle (görevin atandığı kişilere atama yap)</b-dropdown-item>
                  <b-dropdown-item @click="f_addNewTask('wdm17', 'not_assigned', 'not_user', '', 'no', 'yes', task_ind)"><i class="fa fa-edit"></i> subtask ekle (atama yapmadan oluştur)</b-dropdown-item>
                  <b-dropdown-item @click="f_showMessenger(task_ind)"><i class="fa fa-edit"></i> sohbet ekranı </b-dropdown-item>
                  <b-dropdown-item @click="f_showComments(task_ind, 'all_task_list')"><i class="fa fa-edit"></i> yorumları görüntüle</b-dropdown-item>
                  <b-dropdown-item @click="f_showWorkTime(task_ind)"><i class="fa fa-calendar"></i> çalışma zamanı ekle</b-dropdown-item>
                  <b-dropdown-item v-if="f_controlOnlyMyPermission(task, 'can_create_task') && f_isActiveUserEqualOrUpperOfTaskLayerList(task, true) && f_taskStatusThis(['not_assigned', 'assigned', 'recommended', 'cancelled', 'refused', 'accepted'], task)" @click="f_editTask(task_ind, 'edit_all_task_list')"><i class="fa fa-edit"></i> görevi düzenle</b-dropdown-item>
                  <template v-if="f_isActiveUserEqualOrUpperOfTaskLayerList(task, true)">
                    <template v-for="(st, st_ind) in d_wdm17StateOptions">
                      <b-dropdown-item :key="'d_wdm17StateOptions_dropdown_' + st_ind" v-if="f_controlState('all_task_list', st.value, task)" @click="f_saveTaskStatus(st, task_ind, 'all_task_list')"><i class="fa fa-star"></i> {{ st.label.toLocaleLowerCase('tr') }} </b-dropdown-item>
                    </template>
                  </template>
                </b-dropdown>
              </b-col>
              <b-col sm="12" md="2" :style="f_calculateRightPageStyle()">
                <template v-if="task.data && task.data.general && task.data.general.status && task.data.general.status.val && task.data.general.status.val.label">
                  <div :style="f_calculateStatusStyle(task.data.general.status.val.value)"> {{ task.data.general.status.val.label }} </div>
                </template>
                <template v-if="task.user_index_list && Object.keys(task.user_index_list).length > 0">
                  <div v-for="(user, user_ind) in task.user_index_list" style="background: #60ffe4; border-radius: 3px; border: solid 1px #c6cfd4; text-align: left; padding: 2px; margin: 2px; font-size: 10px; width: 100%;"> <i class="fa fa-user"></i> {{ user.label }} [ {{ f_getIndexListAsString(user.index_list) }} ] </div>
                </template>
              </b-col>
              <b-col sm="12" md="2" :style="f_calculateRightPageStyle()">
                <div v-if="f_calculateLastAssignedDate(task)" style="background: #a4fcea; border-radius: 3px; border: solid 1px #c6cfd4; text-align: left; padding: 2px; margin: 2px; font-size: 10px; width: 100%;">
                  <i class="fa fa-calendar"></i> Atama: {{ f_calculateLastAssignedDate(task) }}
                </div>
                <div v-else style="background: #a4fcea; border-radius: 3px; border: solid 1px #c6cfd4; text-align: left; padding: 2px; margin: 2px; font-size: 10px; width: 100%;">
                  <i class="fa fa-calendar"></i> Atama: --->
                </div>
                <div v-if="task.date" style="background: #a4fcea; border-radius: 3px; border: solid 1px #c6cfd4; text-align: left; padding: 2px; margin: 2px; font-size: 10px; width: 100%;">
                  <i class="fa fa-calendar-o"></i> Başlama: {{ DateFormat(task.date) }}
                </div>
                <div v-else style="background: #a4fcea; border-radius: 3px; border: solid 1px #c6cfd4; text-align: left; padding: 2px; margin: 2px; font-size: 10px; width: 100%;">
                  <i class="fa fa-calendar-o"></i> Başlama: --->
                </div>
                <div v-if="task.ending_date" style="background: #a4fcea; border-radius: 3px; border: solid 1px #c6cfd4; text-align: left; padding: 2px; margin: 2px; font-size: 10px; width: 100%;">
                  <i class="fa fa-calendar-plus-o"></i> Bitiş: {{ DateFormat(task.ending_date) }}
                </div>
                <div v-else style="background: #a4fcea; border-radius: 3px; border: solid 1px #c6cfd4; text-align: left; padding: 2px; margin: 2px; font-size: 10px; width: 100%;">
                  <i class="fa fa-calendar-plus-o"></i> Bitiş: --->
                </div>
              </b-col>
              <b-col sm="12" md="7" :style="f_calculateRightPageStyle()">
                <template v-if="task.data && task.data.general && task.data.general.note && task.data.general.note.val">
                  <div style="padding-bottom: 7px;">
                    <span v-if="task.data && task.data.general && task.data.general.task_type && task.data.general.task_type.val && task.data.general.task_type.val.label" style="border: solid 1px #c6cfd4; background: #90c38a; border-radius: 3px; padding: 3px; font-size: 10px;">
                      <i class="fa fa-angle-right"></i>
                       {{ task.data.general.task_type.val.label }} </span>
                    <span v-if="task.data && task.data.general && task.data.general.task_visibility && task.data.general.task_visibility.val && task.data.general.task_visibility.val.label" style="border: solid 1px #c6cfd4; background: #edc541; border-radius: 3px; padding: 3px; font-size: 10px; margin-left: 5px;"> 
                      <i class="fa fa-eye"></i> {{ task.data.general.task_visibility.val.label }} </span>
                    <span v-if="task.data && task.data.general && task.data.general.task_tree_list && task.data.general.task_tree_list.val" style="border: solid 1px #c6cfd4; background: #edc541; border-radius: 3px; padding: 3px; font-size: 10px; margin-left: 5px;"> 
                      <i class="fa fa-eye"></i> görev ağacı mevcut </span>
                    <span v-if="task.data && task.data.general && task.data.general.comment && task.data.general.comment.list && task.data.general.comment.list.length > 0" class="task-comment" @click="f_showComments(task_ind, 'all_task_list')">
                      <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px;" />
                      {{ f_calculateCountOfSeenComments(task) }}
                    </span>
                  </div>
                  <div style="border-radius: 5px; border: dotted 1px #a0d0bc; padding: 3px; margin-top: 5px; background: #ffffff;">{{ task.data.general.note.val }}</div>
                  <div v-if="task.data && task.data.general && task.data.general.drug_medical_equipment && task.data.general.drug_medical_equipment.val && task.data.general.drug_medical_equipment.val.label" style="border-radius: 5px; border: solid 1px #a0d0bc; padding: 3px; margin-top: 5px;"> {{ task.data.general.drug_medical_equipment.val.label }} </div>
                  <template v-if="d_showTaskDocumentField && task.document_list && task.document_list.length > 0" v-for="(doc, doc_ind) in task.document_list">
                    <b-row>
                      <b-col cols="6">
                        <b-button style="margin-right: 5px;" size="sm" variant="danger" @click="f_deleteDocumentInTask(task_ind, doc_ind)"> <i class="fa fa-trash"></i> sil </b-button> {{ doc_ind + 1 }} ) {{ doc.file_name }}
                      </b-col>
                      <b-col cols="6">
                        <b-button style="margin-right: 5px;" size="sm" v-if="['jpeg', 'png', 'jpg'].indexOf(doc.fileExtension.toLowerCase()) !== -1" variant="primary" @click="f_previewImage(task, doc_ind, doc.file_name)">önizle</b-button>
                        <b-button style="margin-right: 5px;" size="sm" variant="secondary" @click="f_downloadDocument(task, doc_ind, doc.file_name)">indir</b-button>
                      </b-col>
                    </b-row>
                  </template>
                  <b-form-file @input="f_addDocumentFastToTask(task_ind)" v-model="d_tmpFile" :accept="f_createAcceptedDocumentTypes()" :placeholder="$t('wdm16.13222')" :drop-placeholder="$t('wdm16.13223')"></b-form-file>
                </template>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <modal v-if="d_showWisdomDataModal3" @close="d_showWisdomDataModal3 = false" :width="'1000'">
      <h3 slot="header"> Ürün Düzenleme Ekranı </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="'wisdom_data_model_3'" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWisdomDataModel3()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showWisdomDataModal3 = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showTaskCommentsModal" @close="d_showTaskCommentsModal = false" :width="'750'">
      <h3 slot="header">
        <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px;" />
        Görev Yorumlar Ekranı
      </h3>
      <div slot="body">
        <b-card no-body style="margin-bottom: 1px;">
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            Yorumlar
          </b-card-header>
          <template v-if="d_selectedTaskForComment.data && d_selectedTaskForComment.data.general && d_selectedTaskForComment.data.general.comment && d_selectedTaskForComment.data.general.comment.list && d_selectedTaskForComment.data.general.comment.list.length > 0">
            <div style="overflow-x: hidden; overflow-y: auto; height: 250px;">
              <template v-for="(com, com_ind) in d_selectedTaskForComment.data.general.comment.list">
                <b-card :key="'d_selectedTaskForComment.data.general.comment.list_' + com_ind" no-body style="margin: 3px; background: #fbffdf;">
                  <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
                    <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px; float: right;" /> {{ com.who_commented.val.label }} {{ com.comment_datetime.val.label }}
                  </b-card-header>
                  <b-row>
                    <b-col sm="12" md="1">
                      <template v-if="d_allOrganizationUsers[com.who_commented.val.value] && d_allOrganizationUsers[com.who_commented.val.value].pic">
                        <img :src="d_allOrganizationUsers[com.who_commented.val.value].pic" width="45px" style="border-radius: 3px; margin: 2px;" />
                      </template>
                      <template v-else>
                        <img src="@/icon/9370.png" width="100%" style="border-radius: 3px;" />
                      </template>
                    </b-col>
                    <b-col sm="12" md="11">
                      <div style="margin-top: 5px; border: dotted 1px #4dbd74; height: 80%; border-radius: 10px; padding: 5px;">
                        {{ com.message.val }}
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </div>
          </template>
        </b-card>
        <b-card no-body>
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            Yeni Yorum Ekle
          </b-card-header>
          <b-row style="margin: 0px;">
            <b-col sm="10" md="10" style="padding: 0px;">
              <b-textarea id="write-comment" v-model="d_newTaskComment" placeholder="Lütfen yorumunuzu yazınız."></b-textarea>
            </b-col>
            <b-col sm="2" md="2" style="padding: 0px;">
              <b-button style="width: 100%; height: 100%;" variant="secondary" @click="f_addNewComment()">
                <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px;" /> ekle
              </b-button>
            </b-col>
          </b-row>
          <b-card no-body v-if="d_filteredWhoCanSeeUsers.length > 0" style="margin-left: 20px; margin-top: 10px; width: 300px;" @mouseleave="d_filteredWhoCanSeeUsers = []">
            <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
              Kişiler
            </b-card-header>
            <div style="overflow-x: hidden; overflow-y: auto; height: 250px; width: 300px; background: #c8ced380; padding: 5px;">
              <template v-for="(us, us_ind) in d_filteredWhoCanSeeUsers">
                <b-row :key="'d_filteredWhoCanSeeUsers_' + us_ind" class="auto-complete-row">
                  <b-col sm="12" @click="f_completeUsername(us.value)">
                    <i class="fa fa-user"></i> {{ us.label }}
                  </b-col>
                </b-row>
              </template>
            </div>
          </b-card>
        </b-card>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showTaskCommentsModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showWorkTimeModal" @close="d_showWorkTimeModal = false" :width="'750'">
      <h3 slot="header">
        <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px;" />
        Görev Çalışma Saatleri
      </h3>
      <div slot="body">
        <b-card no-body style="margin-bottom: 1px;">
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            Çalışma Saatleri
          </b-card-header>
          <template v-if="d_taskList[selected_task_index] && d_taskList[selected_task_index].data && d_taskList[selected_task_index].data.general && d_taskList[selected_task_index].data.general.work_time && d_taskList[selected_task_index].data.general.work_time.list">
            <b-row>
              <b-col sm="12" md="6">Toplam Çalışma Süresi</b-col>
              <b-col sm="12" md="6">
                <strong> Gün: </strong> {{ d_taskWorkTime.day }}
                <strong> Saat: </strong> {{ d_taskWorkTime.hour }}
                <strong> Dakika: </strong> {{ d_taskWorkTime.minute }}
                <strong> Saniye: </strong> {{ d_taskWorkTime.second }}
              </b-col>
            </b-row>
            <div style="overflow-y: auto; overflow-x: hidden; height: 250px;">
              <b-card v-for="(worktime, worktime_ind) in d_taskList[selected_task_index].data.general.work_time.list" :key="'work_time.list_' + worktime_ind" no-body :style="d_workTimeEditMode && d_selectedWorkItemIndex === worktime_ind ? 'margin: 3px; background: #fbffdf; border: dotted 1px black; background: #ccecf9;' : 'margin: 3px; background: #fbffdf;'">
                <!-- <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1"> <img src="@/icon/1519119.png" width="20px" style="border-radius: 3px; float: right;" /> </b-card-header> -->
                <b-row>
                  <b-col sm="12" md="4">
                    <b-row>
                      <b-col sm="12" md="6"> Başlama </b-col>
                      <b-col sm="12" md="6" style="padding-left: 0px; white-space: nowrap;"> {{ worktime.start.val }} </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" md="6"> Bitiş </b-col>
                      <b-col sm="12" md="6" style="padding-left: 0px; white-space: nowrap;"> {{ worktime.end.val }} </b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="12" md="7">
                    <b-row v-if="worktime.work_time_note && worktime.work_time_note.val">
                      <b-col sm="12" md="12"> {{ worktime.work_time_note.val }} </b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="12" md="1" style="align-items: center; display: flex;">
                    <b-dropdown variant="primary" size="md">
                      <template slot="button-content"> </template>
                      <b-dropdown-item @click="f_deleteWorkTime(worktime_ind)"> sil </b-dropdown-item>
                      <b-dropdown-item @click="f_editWorkTime(worktime_ind)"> düzenle </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </template>
        </b-card>
        <b-card no-body>
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            Yeni Çalışma Saati
          </b-card-header>
          <!-- -->
          <b-row>
            <b-col sm="12" md="4">
              <b-row style="padding: 3px;">
                <b-col sm="12" md="4" style="padding-right: 0px;"> Başlama </b-col>
                <b-col sm="12" md="8" style="padding-left: 0px; white-space: nowrap;">
                  <datepicker :config="{ enableTime: true, minDate: '', maxDate: 'today', time_24hr: true}" class="form-control" v-model="d_newWorkTime.start" @input="f_showWorkTimeEnd"></datepicker>
                </b-col>
              </b-row>
              <b-row style="padding: 3px;">
                <b-col sm="12" md="4" style="padding-right: 0px;"> Bitiş </b-col>
                <b-col sm="12" md="8" style="padding-left: 0px; white-space: nowrap;">
                  <datepicker v-if="d_showWorkTimeEnd" :config="{ enableTime: true, minDate: d_newWorkTime.start, maxDate: '', time_24hr: true}" class="form-control" v-model="d_newWorkTime.end"></datepicker>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="12" md="6">
              <b-row style="padding: 3px; margin: 0px;">
                <b-col sm="12" md="12"> Çalışma Notu </b-col>
              </b-row>
              <b-row style="padding: 3px; margin: 0px;">
                <b-col sm="12" md="12">
                  <b-textarea v-model="d_newWorkTime.work_time_note"></b-textarea>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="12" md="2" style="padding: 0px; align-items: center; display: flex;">
              <b-button v-if="d_newWorkTime.start" variant="secondary" @click="f_addEditNewWorkTime()">
                <i v-if="!d_workTimeEditMode" class="fa fa-plus"></i>
                <i v-else-if="d_workTimeEditMode" class="fa fa-edit"></i>
              </b-button>
            </b-col>
          </b-row>
          <!-- -->
        </b-card>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showWorkTimeModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'750'">
      <h3 slot="header"> Görev Ekleme & Düzenleme Ekranı </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="'wdm17'" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveTask()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showMessenger" @close="d_showMessenger = false" large="True">
      <h3 slot="header"> <img src="@/icon/1519119.png" style="width: 2em;" /> WisdomEra Messenger </h3>
      <div slot="body">
        <messenger :organization_data="d_messengerOrganizationData"></messenger>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showMessenger = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showTaskTreeList" @close="d_showTaskTreeList = false" :width="'1500'">
      <h3 slot="header"> <img src="@/icon/1519119.png" style="width: 2em;" /> Görev Ağacı Ekranı </h3>
      <div slot="body">
        <tree-list :organization_id="d_taskTreeListOrganizationId" :show_mode="tree_list.task_tree.show_mode" :tree_list_type="'task_tree_list'" :select_type="tree_list.task_tree.select_type" :task_tree_list_key="tree_list.task_tree.key" :mode="tree_list.task_tree.mode" :selected_items_data_list="tree_list.task_tree.selected_items_data_list"></tree-list>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showTaskTreeList = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showUploadDocumentToPatient" @close="d_showUploadDocumentToPatient = false" :width="'1000'">
      <h3 slot="header">{{ d_selectedTaskForDataUpload.data.general.selected_patient_name.val + ' hastasına ' + d_selectedTaskForDataUpload.data.general.wdm_type.val.label + ' verisi ekleme ekranı'}}</h3>
      <div slot="body">
        <b-card-header header-bg-variant="primary" class="p-1">
          <strong>{{ $t('wdm16.3702') }}</strong>
        </b-card-header>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Döküman Tarihi" label-for="d_documentUploadDetails.date" :label-cols="3">
              <b-form-input type="date" id="d_documentUploadDetails.date" v-model="d_documentUploadDetails.date"></b-form-input>
              <b-form-valid-feedback></b-form-valid-feedback>
              <b-form-invalid-feedback>Döküman tarihi seçilmesi zorunlu alandır</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><strong>Döküman ekle</strong> ({{ f_createAcceptedDocumentTypes() }})</b-col>
          <b-col cols="6">
            <b-form-file @input="f_addDocument()" v-model="d_tmpFile" :accept="f_createAcceptedDocumentTypes()" :placeholder="$t('wdm16.13222')" :drop-placeholder="$t('wdm16.13223')"></b-form-file>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12"><strong>İlişkili Dökümanlar</strong></b-col>
        </b-row>
        <template v-for="(d_type, d_type_ind) in d_fileSystemDocuments">
          <b-card v-if="d_type.list.length > 0" no-body class="mb-1" header-tag="header" footer-tag="footer" border-variant="primary" header-bg-variant="primary">
            <b-card-header header-bg-variant="primary" class="p-1">
              <b-row v-b-toggle="'dtpe_' + d_type_ind" style="cursor: pointer;">
                <b-col cols="12"> {{ d_type_ind }} </b-col>
              </b-row>
            </b-card-header>
            <b-collapse :id="'dtpe_' + d_type_ind">
              <b-row v-for="(file, file_ind) in d_type.list" :key="'d_type.list_' + file_ind">
                <b-col cols="3">
                  {{ file_ind + 1 + '-) ' + file.name }}
                  <a :href="file.file" download>Dökümanı İndir</a>
                </b-col>
                <b-col cols="9">
                  <b-button size="sm" variant="primary" @click="PreviewImage(file.file, file.name)">Önizle</b-button>
                  <b-button size="sm" variant="primary" @click="f_addDocumentFromFileSystem(file)">Dökümanı Ekle</b-button>
                </b-col>
              </b-row>
            </b-collapse>
          </b-card>
        </template>
        <template v-if="d_documentUploadDetails.document_list && d_showDocumentList">
          <b-row v-for="(doc, doc_ind) in d_documentUploadDetails.document_list" style="border-bottom: solid 1px black; margin: 0px;" :key="'document-doc-ind-' + doc_ind">
            <b-col cols="4">
              <b-button size="sm" @click="d_documentUploadDetails.document_list.splice(doc_ind, 1); $forceUpdate();" :key="'document-trash-' + doc_ind"><i class="fa fa-trash"></i></b-button>
              {{ doc.file_name }}
            </b-col>
            <b-col cols="2">
              <img v-if="['jpeg', 'png', 'jpg'].indexOf(doc.fileExtension) !== -1" :src="doc.document" style="width: 50%;">
              <a v-if="['pdf', 'docx', 'doc'].indexOf(doc.fileExtension) !== -1" :href="doc.document" download>{{ $t('wdm16.13224') }}</a>
            </b-col>
          </b-row>
        </template>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_uploadWisdomDataToPatient()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showUploadDocumentToPatient = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="show_image_show_modal" @close="show_image_show_modal = false" :width="'1000'">
      <h3 slot="header">{{ document_show_data.file_name }}</h3>
      <div slot="body">
        <div>
          <b-button variant="success" @click="f_rotateImage()">döndür</b-button>
          <b-button variant="success" @click="f_scaleImageBig()">+</b-button>
          <b-button variant="success" @click="f_scaleImageSmall()">-</b-button>
        </div>
        <div style="overflow-x: auto; overflow-y: auto; min-height: 500px;">
          <img :style="'transform: scale(' + d_scale.toString() + ', ' + d_scale.toString() + ') rotate(' + rotate_degree.toString() + 'deg); position: relative;'" :src="document_show_data.file">
        </div>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="show_image_show_modal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showPatientOrganizationTaskData" @close="d_showPatientOrganizationTaskData = false" :width="'1000'">
      <h3 slot="header">{{ d_patientOrganizationTaskData.data.general.selected_patient_name.val + ' hastasının ' + d_patientOrganizationTaskData.data.general.wdm_type.val.label + ' verilerini düzenleme ekranı'}}</h3>
      <div slot="body">
        <patient-organization-task-data :p_patientOrganizationTaskData="d_patientOrganizationTaskData"></patient-organization-task-data>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="d_showPatientOrganizationTaskData = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showTaskPreviewModal" @close="d_showTaskPreviewModal = false" :width="'1000'">
      <h3 slot="header"> Görev Önizleme </h3>
      <div slot="body">
        <wisdom-data-show :change="data_show_change" :view_mode="'table'" :data="d_showTaskPreviewData" :data_type="'wdm17'" :option_data="option_data['wdm17']"></wisdom-data-show>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="d_showTaskPreviewModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
var d_newTaskCommentKeyLastLocation = '';
import { ClsSearch } from '@/services/public/search';
import {
  default as TreeList
} from '@/components/widgets/TreeList';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
import Datepicker from "wisdom-vue-datepicker";
import { mapGetters } from "vuex";
import {
  default as router
} from "@/router";
import moment from "moment";
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import OrganizationService from '@/services/organization';
import OptionService from '@/services/option';
import UserService from '@/services/users';
import GlobalService from '@/services/global';
import WisdomDataService from '@/services/wisdom_data';
import FileSaver from 'file-saver';
import FileTransferService from '@/services/file_transfer';

export default {
  name: "TaskList",
  computed: {
    ...mapGetters({
      StoreModal: 'modal',
      lang: "lang",
      screen_size: 'screen_size'
    })
  },
  props: {
    selected_organization_item: {
      type: Object,
      required: false
    },
    watch_task_list: {
      type: Object,
      required: false
    },
    organization_data: {
      type: Object,
      required: false
    },
    organization_id: {
      type: String,
      required: false
    },
    right_menu: {
      type: Boolean,
      required: false
    },
    organization_tree: {
      type: Boolean,
      required: false
    },
    left_tree_list_show: {
      type: Boolean,
      required: false
    },
    my_item_index_list: {
      type: Array,
      required: false
    },
    p_getSpecificTasks: {
      type: Object,
      required: false
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted () {
    this.device.width = window.innerWidth;
    this.device.height = window.innerHeight;
    if (this.organization_tree) {
      this.d_searchTaskListData.organization_type = 'this';
      this.d_searchTaskListData.organization_id = this.organization_id;
    }
    let query = 'data_type=wdm17&location=wisdom';
    this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Task wdm datası getiriliyor' } });
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm17'] = resp.data.result;
          this.f_getUserListWithDepartments();
          try {
            this.d_wdm17StateOptions = this.option_data['wdm17'].general.parameters.status.options;
          } catch (err) {
            console.log(err);
            this.d_wdm17StateOptions = [];
          }
          try {
            this.d_wdm17TaskTypeOptions = this.option_data['wdm17'].general.parameters.task_type.options;
            for (let i in this.d_wdm17TaskTypeOptions) {
              this.d_searchTaskListData.task_type_list.push(this.d_wdm17TaskTypeOptions[i].value);
            }
          } catch (err) {
            console.log(err);
            this.d_wdm17TaskTypeOptions = [];
          }
          try {
            this.d_priorityList = this.option_data['wdm17'].general.parameters.priority.options;
            for (let i in this.d_priorityList) {
              this.d_searchTaskListData.priority.push(this.d_priorityList[i].value);
            }
          } catch (err) {
            console.log(err);
            this.d_priorityList = [];
          }
          let data = { 'username': this.user.username };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Organizasyon listesi getiriliyor' } });
          OrganizationService.get_organization_list(data)
            .then(resp => {
              if (resp.data.status === 'success') {
                this.d_organizationList = resp.data.result.list;
                this.d_searchTaskListData.organization_list = [];
                for (let i in this.d_organizationList) {
                  this.d_searchTaskListData.organization_list.push(this.d_organizationList[i].id);
                }
                this.d_canGetTaskListWithWatch = true;
                this.f_getTaskList();
              } else {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                alert('error ', resp.data.message);
              }
            });
        } else {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          console.log('errror ', resp.data.message);
        }
      });
    query = 'data_type=wisdom_data_model_3&location=wisdom';
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wisdom_data_model_3'] = resp.data.result;
        } else {
          console.log('errror ', resp.data.message);
        }
      });
  },
  data () {
    return {
      data_show_change: "0",
      d_selectedAnatomySchemaList: [],
      d_searchText: '',
      d_showMessenger: false,
      d_showTaskPreviewModal: false,
      d_showWisdomDataModal3: false,
      d_showLeftPage: false,
      d_wdm17TaskTypeOptions: [],
      d_searchTaskListData: {
        'search_text': '',
        'organization_list': [],
        'active_user_index_list': [],
        'selected_layer': [],
        'username': '',
        'organization_id': 'uuid',
        'user_type': 'this', // this, all
        'priority': [], // drug_medical_equipment
        'task_type_list': [], // general, drug_medical_equipment, meeting etc.
        'state_list': ['assigned'],
        'organization_type': 'all', // 'this' organization, 'all' organization
        'pagination': { 'start': 0, 'end': 50, 'current': 1, 'perpage': 50 },
        'tree_filter': ['with_tree', 'with_tree_main', 'with_tree_sub', 'without_tree']
      },
      d_taskList: [],
      d_tmpTaskList: [],
      d_taskListCount: 0,
      d_pagination: {
        'start': 0,
        'end': 50,
        'current': 1,
        'perpage': 50
      },
      option_data: {},
      d_userListWithDepartments: [],
      d_wdm17StateOptions: [],
      d_wdm17TaskTypeOptions: [],
      d_priorityList: [],
      selected_task_index: '',
      d_selectedTaskForComment: {},
      d_showTaskCommentsModal: false,
      d_newTaskComment: '',
      d_allOrganizationUsers: {},
      wisdom_data: {},
      data_type: '',
      d_taskTreeListOrganizationId: '',
      show_wisdom_data_modal: false,
      show_image_show_modal: false,
      d_organizationList: {},
      d_selectedTaskForDataUpload: {},
      wisdom_data: {},
      device: { 'width': 0, 'height': 0 },
      d_canGetTaskListWithWatch: false,
      d_showDocumentList: true,
      d_showWorkTimeModal: false,
      d_workTimeList: [],
      d_newWorkTime: { 'start': '', 'end': '', 'work_time_note': '' },
      d_showWorkTimeEnd: true,
      d_showUploadDocumentToPatient: false,
      d_taskWorkTime: {
        'day': 0,
        'hour': 0,
        'minute': 0,
        'second': 0
      },
      d_workTimeEditMode: false,
      d_selectedWorkItemIndex: '',
      d_whoCanSeeThisTask: [],
      d_filteredWhoCanSeeUsers: [],
      d_filterAbility: true,
      d_showTaskTreeList: false,
      d_documentUploadDetails: { 'patient_id': '', 'hospital_group': '', 'hospital_id': '', 'document_list': [], 'created_by': '', 'date': '', 'label': '', 'type': '' },
      document_show_data: { 'file': '', 'file_name': '' },
      d_fileSystemDocuments: {},
      d_patientOrganizationTaskData: {},
      d_showPatientOrganizationTaskData: false,
      d_messengerOrganizationData: { 'organization_id': '', 'task_id': '' },
      rotate_degree: 0,
      d_scale: 1,
      d_tmpFile: '',
      d_showTaskDocumentField: true,
      tree_list: {
        'task_tree': {
          'show_items_on_top': false,
          'max_height': '300',
          'selected_items_data_list': [],
          'mode': 'edit', // ['edit', 'use']
          'show_mode': 'treeView',
          'select_type': 'one', // ['one', 'multi']
          'key': ''
        }
      },
      d_showTaskPreviewData: {}
    };
  },
  methods: {
    f_showTaskPreviewModal: function (task) {
      this.d_showTaskPreviewData = task;
      this.d_showTaskPreviewModal = true;
    },
    f_showTrueFalseTaskDocumentField: function () {
      this.d_showTaskDocumentField = false;
      setTimeout(function () {
        this.d_showTaskDocumentField = true;
      }.bind(this), 5)
    },
    f_addDocumentFastToTask: function (task_ind) {
      let task_data = this.d_taskList[task_ind];
      let file_name = this.d_tmpFile.name;
      let arr = this.d_tmpFile.name.split('.');
      let fileExtension = arr[arr.length - 1];
      if (["jpg", "jpeg", "png", "docx", "doc", "pdf", "xls", "xlsx"].indexOf(fileExtension.toLowerCase()) === -1) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert('yasaklı dosya tipi');
        return;
      }
      if (this.d_tmpFile.size > 10485760) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert(this.$t('wdm16.12730'));
        return;
      }
      var reader = new FileReader();
      var vm = this;
      if (!task_data.document_list) {
        task_data.document_list = [];
      }
      let doc_data = {
        'record_status': 'add_edit',
        'status': 'upload',
        'user': this.user.username,
        'document': '',
        'information': '',
        'fileExtension': fileExtension,
        'file_name': file_name
      };
      task_data.document_list.push(doc_data);
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        // console.log(reader.result);
        task_data.document_list[task_data.document_list.length - 1].document = reader.result;
        vm.f_saveTaskGeneral(task_ind, task_data);
      }, false);
      reader.readAsDataURL(this.d_tmpFile);
      this.d_tmpFile = '';
    },
    f_previewImage: function (wdmr, doc_ind, file_name) {
      if (wdmr.document_list[doc_ind].document) {
        this.document_show_data.file_name = wdmr.document_list[doc_ind].file_name;
        this.document_show_data.file = wdmr.document_list[doc_ind].document;
        this.show_image_show_modal = true;
      } else {
        let data = { 'key_list': [] };
        let key = wdmr.type + '_' + wdmr.id + '_doc' + doc_ind.toString();
        data.key_list.push(key);
        console.log(data);
        FileTransferService.get_file_with_key(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              if (resp.data.result[key].file) {
                this.document_show_data.file_name = file_name;
                this.document_show_data.file = resp.data.result[key].file;
                this.show_image_show_modal = true;
              }
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    },
    f_downloadDocument: function (wdmr, doc_ind, file_name) {
      if (wdmr.document_list[doc_ind].document) {
        FileSaver.saveAs(wdmr.document_list[doc_ind].document, file_name);
      } else {
        let data = { 'key_list': [] };
        let key = wdmr.type + '_' + wdmr.id + '_doc' + doc_ind.toString();
        data.key_list.push(key);
        FileTransferService.get_file_with_key(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              FileSaver.saveAs(resp.data.result[key].file, file_name);
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    },
    f_createWdm21ForTask: function (task) {
      let modal_data = { 'type': 'confirm', 'text': 'Bugüne ait tarihle birlikte, bu data türüne ait bilgiye ulaşılamadı bilgisini eklemek istediğinize emin misiniz?', 'centered': true, 'title': 'Dataya Ulaşılamadı Bilgisi Ekleme', 'confirm': false, 'function_name': 'f_createWdm21ForTaskConfirmed', 'arguments': [task] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_createWdm21ForTaskConfirmed: function (task) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Data bulunamadı bilgisi kaydediliyor' } });
      OrganizationService.create_wdm21_for_task({ 'task': task })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
    },
    f_uploadWisdomDataToPatient: function () {
      if (!this.d_documentUploadDetails.date) {
        alert("Lütfen tarih giriniz");
        return;
      }
      if (!this.d_documentUploadDetails.document_list || this.d_documentUploadDetails.document_list.length == 0) {
        alert("Lütfen en az bir adet döküman yükleyin");
        return;
      }
      let wdm_version = this.d_selectedTaskForDataUpload.data.general.wdm_version.val.label;
      let wdm_version_key = this.d_selectedTaskForDataUpload.data.general.wdm_version.val.value;
      let wdm_owner_type = this.d_selectedTaskForDataUpload.data.general.wdm_group.val.value;
      let wdm_type_label = this.d_selectedTaskForDataUpload.data.general.wdm_type.val.label;
      let wdm_type_value = this.d_selectedTaskForDataUpload.data.general.wdm_type.val.value;
      let department_value = this.d_selectedTaskForDataUpload.data.general.selected_hospital_department.val.value;

      let d_uuid = this.d_documentUploadDetails.id ? this.d_documentUploadDetails.id : '';
      this.d_documentUploadDetails.label = this.d_documentUploadDetails.date + ' ' + wdm_type_label;


      let service_data = { 'key': wdm_version_key, 'bucket': '' };
      if (wdm_owner_type === 'wisdom') {
        service_data.bucket = 'option'
      } else if (['user', 'hospital'].indexOf(wdm_owner_type) !== -1) {
        service_data.bucket = 'hospital'
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Data kaydediliyor' } });
      GlobalService.get_one_cb_document(service_data)
        .then(resp => {
          let wdm_version_data = JSON.parse(JSON.stringify(resp.data.result));
          this.d_documentUploadDetails.data = {};
          for (let i in wdm_version_data.param_group.param_order) {
            this.d_documentUploadDetails.data[wdm_version_data.param_group.param_order[i]] = {};
          }
          this.d_documentUploadDetails.department = department_value;
          this.d_documentUploadDetails.reference_wdm = {
            'owner_type': wdm_owner_type,
            'version': wdm_version,
            'key': wdm_version_key
          };
          let data = {
            'data': this.d_documentUploadDetails
          };
          // data.type = wdm_type_value;
          data.hospital_group = this.d_selectedTaskForDataUpload.data.general.selected_patient_hospital_group.val;
          data.hospital_id = this.d_selectedTaskForDataUpload.data.general.selected_patient_hospital_id.val;
          data.data.organization = { 'id': this.d_selectedTaskForDataUpload.organization_data.id, 'label': this.d_selectedTaskForDataUpload.organization_data.name, 'task_id': this.d_selectedTaskForDataUpload.id };
          // let return_data = ClsWdmrForever.turn(this.d_documentUploadDetails, this.option_data[this.data_type], ['name']);
          // let wdmr_name = return_data.name;
          // this.d_documentUploadDetails['label'] = wdmr_name;
          // this.$refs.wisdom_data_modal.calculate_name();
          WisdomDataService.save_wdm(this.d_documentUploadDetails.patient_id, d_uuid, data)
            .then(resp => {
              if (resp.data.status === "success") {
                this.d_showUploadDocumentToPatient = false;
                this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              }
            });
        });
    },
    f_rotateImage: function () {
      if (this.rotate_degree === 360) {
        this.rotate_degree = 90;
      } else {
        this.rotate_degree += 90;
      }
    },
    f_scaleImageBig: function () {
      // this.f_showTrueFalse();
      this.d_scale += 0.1;
    },
    f_scaleImageSmall: function () {
      // this.f_showTrueFalse();
      this.d_scale -= 0.1;
    },
    f_deleteTaskFromAllUsers: function (list_data, task_key) {
      for (let i in list_data) {
        if (list_data[i].task_list && list_data[i].task_list.indexOf(task_key) !== -1) {
          list_data[i].task_list.splice(list_data[i].task_list.indexOf(task_key), 1);
        }
        if ('list' in list_data[i] && list_data[i].list.length > 0) {
          this.f_deleteTaskFromAllUsers(list_data[i].list, task_key);
        }
      }
    },
    f_giveTaskToSelectedLayer: function (type, task_ind) {
      let task_data = JSON.parse(JSON.stringify(this.d_taskList[task_ind]));
      let task_key = 'organization_' + task_data['organization_id'] + '_task_' + task_data['id'];
      if (!this.selected_organization_item.task_list || (this.selected_organization_item.task_list && this.selected_organization_item.task_list.indexOf(task_key) === -1)) {
        if (type === 'transfer') {
          console.log(this.organization_data);
          this.f_deleteTaskFromAllUsers(this.organization_data.list, task_key);
          this.$delete(this.d_taskList[task_ind], 'user_index_list');
        }
        if (!this.selected_organization_item.task_list) {
          this.selected_organization_item.task_list = [];
        }
        this.selected_organization_item.task_list.push(task_key);
        if (!this.d_taskList[task_ind].user_index_list) {
          this.d_taskList[task_ind].user_index_list = {};
        }
        this.d_taskList[task_ind].user_index_list[this.selected_organization_item.value] = {
          'index_list': [],
          'label': this.selected_organization_item.label,
          'value': this.selected_organization_item.value
        };
        if (this.d_taskList[task_ind].user_index_list[this.selected_organization_item.value].index_list.indexOf(this.selected_organization_item.index) === -1) {
          this.d_taskList[task_ind].user_index_list[this.selected_organization_item.value].index_list.push(this.selected_organization_item.index);
        }
        this.$forceUpdate();
        this.f_saveTaskStatus({ 'label': 'Atandı', 'value': 'assigned' }, task_ind, 'all_task_list', true);
        this.f_sendParentInfo('save_organization');
      } else {
        alert('Bu görev bu kullanıcıda zaten eklidir.');
      }
    },
    f_sendParentInfo: function (op) {
      if (this.watch_task_list[op] === 0) {
        this.watch_task_list[op] = 1;
      } else {
        this.watch_task_list[op] = 0;
      }
    },
    f_calculateRightPageStyle: function () {
      if (this.right_menu) {
        return 'min-width: 250px; padding: 3px;';
      } else if (this.organization_tree) {
        return 'min-width: 125px; padding: 3px;';
      } else {
        return 'padding: 3px;';
      }
    },
    f_calculateLeftPageClass: function () {
      if (this.right_menu) {
        return 'wisdom-right-menu-item-open';
      } else if (this.organization_tree) {
        return 'wisdom-left-menu-item-open-look-margin';
      } else {
        return 'wisdom-left-menu-item-open';
      }
    },
    f_showTaskTreeList: function (task, task_tree_list_key, task_ind) {
      this.d_taskTreeListOrganizationId = task.organization_data.id;
      this.tree_list.task_tree.key = task_tree_list_key;
      this.d_showTaskTreeList = true;
    },
    f_addNewTask: function (data_type, status, user_type, selected_index = '', mail = 'no', subtask = 'no', task_ind = '') {
      let task_data = this.d_taskList[task_ind];
      let main_task_id = task_data.id;
      this.d_newTaskUserType = user_type;
      this.data_type = data_type;
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': data_type,
        'reference_wdm': {},
        'organization_id': task_data.organization_data.id,
        'selected_index': [selected_index], // ilerde çoklu katmana hızlı atama yapılabileceği için böyle yapıldı
        'task_user_index_list': task_data.user_index_list,
        'subtask': subtask,
        'main_task_id': main_task_id,
        'mail': mail
      };
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      let selected_status = '';
      for (let i in this.d_wdm17StateOptions) {
        if (this.d_wdm17StateOptions[i].value === status) {
          selected_status = JSON.parse(JSON.stringify(this.d_wdm17StateOptions[i]));
          pre_data.data.general.status = { 'val': JSON.parse(JSON.stringify(this.d_wdm17StateOptions[i])) };
          break;
        }
      }
      if (user_type === 'user') {
        if (!pre_data.data.general.log) {
          pre_data.data.general.log = { 'list': [] };
        }
        for (let us_ind in task_data.user_index_list) {
          let x = {
            'log_user': {
              'val': {
                'label': this.user.first_name + ' ' + this.user.last_name,
                'value': this.user.username
              }
            },
            'which_user_log': {
              'val': {
                'label': task_data.user_index_list[us_ind].label,
                'value': task_data.user_index_list[us_ind].value
              }
            },
            'log_status': {
              'val': selected_status
            },
            'log_datetime': {
              'val': 'new'
            }
          };
          pre_data.data.general.log.list.push(JSON.parse(JSON.stringify(x)));
        }
      }
      this.wisdom_data = pre_data;
      this.show_wisdom_data_modal = true;
    },
    f_showMessenger: function (task_ind) {
      let task_data = this.d_taskList[task_ind];
      let data = {
        'task_id': this.d_taskList[task_ind].id,
        'organization_id': this.d_taskList[task_ind].organization_data.id
      };
      this.d_messengerOrganizationData = data;
      OrganizationService.create_organization_messenger_group(data)
        .then(resp => {
          console.log(resp);
          if (resp.data.status === 'success') {
            this.d_showMessenger = true;
          }
        });
    },
    f_completeUsername: function (text) {
      let x = '';
      for (let i = d_newTaskCommentKeyLastLocation - 1; i >= 0; i--) {
        if (this.d_newTaskComment[i] === '@' || this.d_newTaskComment[i] === '#') {
          x = i;
          break;
        }
      }
      // console.log('x: ', x);
      // console.log('d_newTaskCommentKeyLastLocation: ', d_newTaskCommentKeyLastLocation);
      // console.log('this.d_newTaskComment: ', this.d_newTaskComment);
      let first_part = this.d_newTaskComment.slice(0, x + 1);
      let end_part = this.d_newTaskComment.slice(d_newTaskCommentKeyLastLocation, this.d_newTaskComment.length - 1);
      // console.log('this.d_newTaskComment: ', this.d_newTaskComment);
      // console.log('text: ', text);
      this.d_newTaskComment = first_part + text + ' ' + end_part;
      d_newTaskCommentKeyLastLocation = this.d_newTaskComment.length;
      this.d_filteredWhoCanSeeUsers = [];
      this.d_filterAbility = false;
      setTimeout(function () {
          this.d_filterAbility = true;
        }.bind(this), 1)
        // this.$forceUpdate();
    },
    f_editWorkTime: function (worktime_ind) {
      this.d_selectedWorkItemIndex = worktime_ind;
      this.d_workTimeEditMode = true;
      this.d_newWorkTime.start = this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].start.val;
      if (this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].end && this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].end.val) {
        this.d_newWorkTime.end = this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].end.val;
      }
      if (this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].work_time_note && this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].work_time_note.val) {
        this.d_newWorkTime.work_time_note = this.d_taskList[this.selected_task_index].data.general.work_time.list[worktime_ind].work_time_note.val;
      }
      this.$forceUpdate();
    },
    f_calculateTotalWorkTime: function (task) {
      if (this.d_taskList[this.selected_task_index].data.general.work_time && this.d_taskList[this.selected_task_index].data.general.work_time.list && this.d_taskList[this.selected_task_index].data.general.work_time.list.length > 0) {
        let total_miliseconds = 0;
        for (let i in this.d_taskList[this.selected_task_index].data.general.work_time.list) {
          let start = '';
          let end = '';
          try {
            start = this.d_taskList[this.selected_task_index].data.general.work_time.list[i].start.val;
            end = this.d_taskList[this.selected_task_index].data.general.work_time.list[i].end.val;
          } catch (err) {}
          if (start && end) {
            let start_date = new Date(start);
            let end_date = new Date(end);
            let start_date_mSec = start_date.getTime();
            let end_date_mSec = end_date.getTime();
            total_miliseconds += end_date_mSec - start_date_mSec;
          }
        }
        if (total_miliseconds >= 86400000) {
          let k = parseInt((total_miliseconds / 86400000).toString().split('.')[0]);
          this.d_taskWorkTime.day = k.toString();
          total_miliseconds -= (k * 86400000);
        }
        if (total_miliseconds >= 3600000) {
          let k = parseInt((total_miliseconds / 3600000).toString().split('.')[0]);
          this.d_taskWorkTime.hour = k.toString();
          total_miliseconds -= (k * 3600000);
        }
        if (total_miliseconds >= 60000) {
          let k = parseInt((total_miliseconds / 60000).toString().split('.')[0]);
          this.d_taskWorkTime.minute = k.toString();
          total_miliseconds -= (k * 60000);
        }
        if (total_miliseconds >= 1000) {
          let k = parseInt((total_miliseconds / 1000).toString().split('.')[0]);
          this.d_taskWorkTime.second = k.toString();
          total_miliseconds -= (k * 1000);
        }
      }
    },
    f_deleteWorkTime: function (worktime_ind) {
      this.d_taskList[this.selected_task_index].data.general.work_time.list.splice(worktime_ind, 1);
      OrganizationService.save_task(this.d_taskList[this.selected_task_index])
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_taskList[this.selected_task_index] = resp.data.result;
            this.f_calculateTotalWorkTime(this.d_taskList[this.selected_task_index]);
            this.$forceUpdate();
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_showWorkTimeEnd: function () {
      this.d_showWorkTimeEnd = false;
      setTimeout(function () {
        this.d_showWorkTimeEnd = true;
      }.bind(this), 1)
    },
    f_addEditNewWorkTime: function () {
      let task_data = this.d_taskList[this.selected_task_index];
      if (this.d_newWorkTime.start) {
        if (!task_data.data.general.work_time) {
          task_data.data.general.work_time = { 'list': [] };
        }
        let start = '';
        let end = '';
        let new_start = '';
        let new_startMsec = 0;
        let new_end = '';
        let new_endMsec = 0;
        // console.log(this.d_newWorkTime);
        if (this.d_newWorkTime.start && this.d_newWorkTime.end) {
          for (let i in task_data.data.general.work_time.list) {
            try {
              start = task_data.data.general.work_time.list[i].start.val;
              end = task_data.data.general.work_time.list[i].end.val;
              new_start = new Date(this.d_newWorkTime.start);
              new_startMsec = new_start.getTime();
              new_end = new Date(this.d_newWorkTime.end);
              new_endMsec = new_end.getTime();
            } catch (err) {}
            // console.log(start, end);
            if (start && end) {
              let start_date = new Date(start);
              let end_date = new Date(end);
              let start_date_mSec = start_date.getTime();
              let end_date_mSec = end_date.getTime();
              // console.log('start_date_mSec: ', start_date_mSec);
              // console.log('end_date_mSec: ', end_date_mSec);
              // console.log('new_startMsec: ', new_startMsec);
              // console.log('new_endMsec: ', new_endMsec);
              if ((new_startMsec >= start_date_mSec && new_startMsec < end_date_mSec) || (new_endMsec > start_date_mSec && end_date_mSec >= new_endMsec)) {
                alert('Daha önce belirttiğiniz çalışma aralığını lütfen belirtmeyiniz.');
                return;
              }
            }
          }
        }
        let prepare_work_time = {
          'start': { 'val': this.d_newWorkTime.start },
          'end': { 'val': this.d_newWorkTime.end },
          'work_time_note': { 'val': this.d_newWorkTime.work_time_note }
        };
        if (this.d_workTimeEditMode) {
          task_data.data.general.work_time.list[this.d_selectedWorkItemIndex] = prepare_work_time;
        } else {
          task_data.data.general.work_time.list.push(prepare_work_time);
        }
        OrganizationService.save_task(task_data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_taskList[this.selected_task_index] = resp.data.result;
              this.f_calculateTotalWorkTime(this.d_taskList[this.selected_task_index]);
              this.d_workTimeEditMode = false;
              this.d_newWorkTime = {
                'start': '',
                'end': '',
                'work_time_note': ''
              };
              this.$forceUpdate();
            } else {
              alert('error ' + resp.data.message);
            }
          });
      }
    },
    f_showWorkTime: function (task_ind) {
      this.d_workTimeEditMode = false;
      this.d_newWorkTime = {
        'start': '',
        'end': '',
        'work_time_note': ''
      };
      this.selected_task_index = task_ind;
      this.f_calculateTotalWorkTime(this.d_taskList[this.selected_task_index]);
      this.d_showWorkTimeModal = true;
    },
    f_calculateLastAssignedDate: function (task) {
      // TODO
      try {
        for (let i = 0; i < task.data.general.log.list.length; i++) {
          if (task.data.general.log.list[i].log_status.val.value === 'assigned') {
            let k = this.DateFormatWithTime(task.data.general.log.list[i].log_datetime.val);
            if (k) {
              return k;
            } else {
              return '';
            }
          }
        }
      } catch (err) {}
      return '';
    },
    f_saveWisdomDataModel3: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data['wisdom_data_model_3'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let barcode_no = this.wisdom_data['data']['1000']['barcode_number']['val'];
      let data = { 'barcode_no': barcode_no, 'brand_data': this.wisdom_data };
      OptionService.save_wdm_brand_details(data)
        .then(resp => {
          let msg = ''
          if (resp.data.status === "success") {
            msg = 'Başarıyla kaydedildi';
            this.d_showWisdomDataModal3 = false;
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_editPatientData: function (task) {
      this.d_patientOrganizationTaskData = {};
      this.d_patientOrganizationTaskData = JSON.parse(JSON.stringify(task));
      // this.d_patientOrganizationTaskData.patient_id = task.data.general.selected_patient_id.val;
      // this.d_patientOrganizationTaskData.organization_id = task.organization_data.id;
      // this.d_patientOrganizationTaskData.task_id = task.id;
      // this.d_patientOrganizationTaskData.wdm_type = task.data.general.wdm_type.val.value;
      // this.d_patientOrganizationTaskData.hospital_group = task.data.general.selected_patient_hospital_group.val;
      // this.d_patientOrganizationTaskData.hospital_id = task.data.general.selected_patient_hospital_id.val;
      this.d_showPatientOrganizationTaskData = true;
    },
    f_uploadPatientData: function (task) {
      this.d_selectedTaskForDataUpload = JSON.parse(JSON.stringify(task));
      let data = {};
      data.hospital_group = task.data.general.selected_patient_hospital_group.val;
      data.hospital_id = task.data.general.selected_patient_hospital_id.val;
      data.projectdate = task.data.general.selected_patient_projectdate.val;
      data.hims_patient_id = task.data.general.selected_patient_hims_patient_id.val;
      this.d_fileSystemDocuments = {};
      OrganizationService.get_patient_file_system_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_fileSystemDocuments = resp.data.result;
            this.d_documentUploadDetails = {
              'patient_id': task.data.general.selected_patient_id.val,
              'hospital_group': task.data.general.selected_patient_hospital_group.val,
              'hospital_id': task.data.general.selected_patient_hospital_id.val,
              'document_list': [],
              'created_by': this.user.username,
              'date': '',
              'label': '',
              'type': task.data.general.wdm_type.val.value
            };
            // this.d_documentUploadDetails.created_by = this.user.username;
            // this.d_documentUploadDetails.patient_id = task.data.general.selected_patient_id.val;
            // this.d_documentUploadDetails.hospital_group = data.hospital_group;
            // this.d_documentUploadDetails.hospital_id = data.hospital_id;
            this.d_showUploadDocumentToPatient = true;
          } else {
            alert('error: ', resp.data.message);
          }
        });
    },
    f_addDocumentFromFileSystem: function (file_data) {
      this.d_showDocumentList = false;
      if (!this.d_documentUploadDetails.document_list) {
        this.d_documentUploadDetails.document_list = [];
      }
      let fileExtension = '';
      let x = file_data.name.split('.');
      fileExtension = x[x.length - 1];
      let doc_data = {
        'record_status': 'add_edit',
        'status': 'upload',
        'user': this.user.username,
        'document': file_data.file,
        'information': '',
        'fileExtension': fileExtension,
        'file_name': file_data.name,
        'organization': { 'id': this.d_selectedTaskForDataUpload.organization_data.id, 'label': this.d_selectedTaskForDataUpload.organization_data.name, 'task_id': this.d_selectedTaskForDataUpload.id }
      };
      this.d_documentUploadDetails.document_list.push(doc_data);
      this.d_showDocumentList = true;
      this.$forceUpdate();
    },
    f_addDocument: function (task_ind) {
      this.d_showDocumentList = false;
      let arr = this.d_tmpFile.name.split('.');
      let fileExtension = arr[arr.length - 1];
      if (!this.d_documentUploadDetails.document_list) {
        this.d_documentUploadDetails.document_list = [];
      }
      if (["jpg", "jpeg", "png", "docx", "doc", "pdf"].indexOf(fileExtension) === -1) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert('yasaklı dosya tipi');
        return;
      }
      if (this.d_tmpFile.size > 10485760) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert(this.$t('wdm16.12733'));
        return;
      }

      var reader = new FileReader();
      // console.log(reader.result);
      let doc_data = {
        'record_status': 'add_edit',
        'status': 'upload',
        'user': this.user.username,
        'document': '',
        'information': '',
        'fileExtension': fileExtension,
        'file_name': this.d_tmpFile.name,
        'organization': { 'id': this.d_selectedTaskForDataUpload.organization_data.id, 'label': this.d_selectedTaskForDataUpload.organization_data.name, 'task_id': this.d_selectedTaskForDataUpload.id }
      };
      this.d_documentUploadDetails.document_list.push(doc_data);
      var vm = this;
      reader.addEventListener("load", function () {
        vm.d_documentUploadDetails.document_list[vm.d_documentUploadDetails.document_list.length - 1].document = reader.result;
      }, false);
      reader.readAsDataURL(this.d_tmpFile);
      this.d_tmpFile = '';
      this.d_showDocumentList = true;
      this.$forceUpdate();
    },
    f_createAcceptedDocumentTypes: function () {
      let accept = '';
      let file_types = ["jpg", "jpeg", "png", "docx", "doc", "pdf", "xlsx", "xls"];
      for (let i in file_types) {
        accept += '.' + file_types[i] + ',';
      }
      return accept;
    },
    PreviewImage: function (file, file_name) {
      this.document_show_data.file_name = file_name;
      this.document_show_data.file = file;
      this.show_image_show_modal = true;
    },
    f_editBrand: function (task) {
      let barcode_no = '';
      let brand_name = '';
      let ingredient_value = '';
      let ingredient_name = '';
      if (task.data && task.data.general && task.data.general.drug_medical_equipment && task.data.general.drug_medical_equipment.val && task.data.general.drug_medical_equipment.val.value) {
        barcode_no = task.data.general.drug_medical_equipment.val.value;
        brand_name = task.data.general.drug_medical_equipment.val.label;
        ingredient_value = task.data.general.drug_medical_equipment.val.ingredient_value;
        ingredient_name = task.data.general.drug_medical_equipment.val.ingredient_name;
      }
      if (barcode_no) {
        GlobalService.get_one_cb_document({ 'key': 'wisdom_data_model_3_' + barcode_no, 'bucket': 'option' })
          .then(resp => {
            if (resp.data.status === 'success') {
              if (resp.data.result.id) {
                this.wisdom_data = resp.data.result;
                for (let i in this.option_data['wisdom_data_model_3'].param_group.param_order) {
                  if (!this.wisdom_data['data'][this.option_data['wisdom_data_model_3'].param_group.param_order[i]]) {
                    this.wisdom_data['data'][this.option_data['wisdom_data_model_3'].param_group.param_order[i]] = {};
                  }
                }
              } else {
                let pre_data = {
                  'id': 'new',
                  'data': {},
                  'type': 'wisdom_data_model_3',
                  'reference_wdm': {},
                  'parent': 'wisdom_data_model_3'
                };
                pre_data['reference_wdm']['version'] = this.option_data['wisdom_data_model_3']['version'];
                pre_data['reference_wdm']['owner_type'] = this.option_data['wisdom_data_model_3']['owner_type'];
                pre_data['reference_wdm']['key'] = 'wdm_wisdom_wisdom_data_model_3_v' + this.option_data['wisdom_data_model_3'].version;
                for (let i in this.option_data['wisdom_data_model_3'].param_group.param_order) {
                  pre_data['data'][this.option_data['wisdom_data_model_3'].param_group.param_order[i]] = {};
                }
                pre_data['related_wdm_key'] = 'wdm2_' + ingredient_value;
                pre_data['data']['1000']['ingredient_value'] = { 'val': '' };
                pre_data['data']['1000']['ingredient_name'] = { 'val': '' };
                pre_data['data']['1000']['barcode_number'] = { 'val': '' };
                pre_data['data']['1000']['brand_name'] = { 'val': '' };
                pre_data['data']['1000']['barcode_number']['val'] = barcode_no;
                pre_data['data']['1000']['brand_name']['val'] = brand_name;
                pre_data['data']['1000']['ingredient_value']['val'] = ingredient_value;
                pre_data['data']['1000']['ingredient_name']['val'] = ingredient_name;
                this.wisdom_data = pre_data;
              }
              this.d_showWisdomDataModal3 = true;
            } else {
              alert('Error : ' + resp.data.message);
            }
          });
      } else {
        alert('Ürün seçimi yapılmamış');
      }
    },
    f_goToThisOrganization: function (organization_id) {
      this.$router.push({ path: '/whsmodules/organization/organization', query: { 'organization_id': organization_id } });
    },
    f_calculateRightSideStyle: function () {
      let x = '';
      if (this.right_menu) {
        x = (300 - 50).toString() + 'px;';
      } else if (this.organization_tree) {
        if (this.left_tree_list_show) {
          x = (((this.screen_size.width / 12) * 7) - 75).toString() + 'px;';
        } else {
          x = (this.screen_size.width - 75).toString() + 'px;';
        }
      } else {
        x = (this.screen_size.width - 60).toString() + 'px;';
      }
      return 'overflow-y: auto; overflow-x: hidden; float: left; width: ' + x + 'height: ' + (this.device.height - 200).toString() + 'px; background: #04618c;';
    },
    f_calculatePriorityStyle: function (task) {
      let task_priority = '';
      try {
        task_priority = task.data.general.priority.val.value;
      } catch (err) {
        task_priority = '';
      }
      if (task_priority === 'urgent') {
        return 'float: left; background: red; height: 100%; width: 12px; margin-right: 3px; border-radius: 7px; box-shadow: -2px -2px 10px;';
      } else if (task_priority === 'high') {
        return 'float: left; background: #ed6e40; height: 100%; width: 12px; margin-right: 3px; border-radius: 7px; box-shadow: -2px -2px 10px;';
      } else if (task_priority === 'medium') {
        return 'float: left; background: #f1ae54; height: 100%; width: 12px; margin-right: 3px; border-radius: 7px; box-shadow: -2px -2px 10px;';
      } else if (task_priority === 'low') {
        return 'float: left; background: #f7cd46; height: 100%; width: 12px; margin-right: 3px; border-radius: 7px; box-shadow: -2px -2px 10px;';
      }
    },
    f_saveTaskStatus: function (selected_status, task_ind, type, use_selected) {
      let task_data = {};
      task_data = JSON.parse(JSON.stringify(this.d_taskList[task_ind]));
      task_data.data.general.status = { 'val': selected_status };
      if (!task_data.data.general.log) {
        task_data.data.general.log = { 'list': [] };
      }
      let which_user_log = {
        'val': {
          'label': this.user.first_name + ' ' + this.user.last_name,
          'value': this.user.username
        }
      };
      if (use_selected) {
        which_user_log.val.label = this.selected_organization_item.label;
        which_user_log.val.value = this.selected_organization_item.value;
      }
      let x = {
        'log_user': {
          'val': {
            'label': this.user.first_name + ' ' + this.user.last_name,
            'value': this.user.username
          }
        },
        'which_user_log': which_user_log,
        'log_status': {
          'val': selected_status
        },
        'log_datetime': {
          'val': 'new'
        }
      };
      task_data.data.general.log.list.push(JSON.parse(JSON.stringify(x)));
      OrganizationService.save_task(task_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_taskList[task_ind] = resp.data.result;
            this.$forceUpdate();
            this.show_wisdom_data_modal = false;
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_controlState: function (type, status, task_data) {
      let task_status = task_data.data.general.status.val.value;
      // if the same status, return false
      if (task_status === status) {
        return false;
      }
      if (['assigned', 'recommended'].indexOf(status) !== -1) {
        return false;
      }
      if (['not_assigned'].indexOf(status) !== -1 && type === 'all_task_list') {
        return false;
      }
      if (['completed'].indexOf(task_status) === -1 && ['checking_task_completion'].indexOf(status) !== -1) {
        return false;
      }
      if (['checking_task_completion'].indexOf(task_status) === -1 && ['approved_task_completion'].indexOf(status) !== -1) {
        return false;
      }
      let my_item_index_list = [];
      if (task_data['organization_data']) {
        for (let i in task_data['organization_data']['perms_and_index_list']) {
          my_item_index_list.push(task_data['organization_data']['perms_and_index_list'][i].index);
        }
      }
      if (my_item_index_list.length === 0) {
        return false;
      }
      // if user did not assign to this task, return false
      if (['refused', 'accepted', 'started', 'pending', 'completed'].indexOf(status) !== -1) {
        if (!task_data.index_list) {
          // not assigned to any user
          return false;
        } else {
          let det = false;
          for (let i in task_data.index_list) {
            for (let k in my_item_index_list) {
              if (task_data.index_list[i] === my_item_index_list[k]) {
                // this task has been assigned to active user
                det = true;
                break;
              }
            }
            if (det) {
              break;
            }
          }
          if (!det) {
            return false;
          } else {
            // here controlling the task data status and button status relationship
            if (['assigned'].indexOf(task_status) !== -1 && ['pending'].indexOf(status) !== -1) {
              return false;
            }
            if (['started'].indexOf(task_status) === -1 && ['completed'].indexOf(status) !== -1) {
              return false;
            }
          }
        }
      }
      return true;
    },
    f_saveTaskGeneral: function (task_ind, task_data) {
      OrganizationService.save_task(task_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            // console.log(resp.data.result);
            this.d_taskList[task_ind] = resp.data.result;
            this.f_showTrueFalseTaskDocumentField();
            this.$forceUpdate();
          } else {
            console.log('error :' + resp.data.message);
          }
        });
    },
    f_deleteDocumentInTask: function (task_ind, doc_ind) {
      let task_data = this.d_taskList[task_ind];
      task_data.document_list[doc_ind]['record_status'] = 'deleted';
      this.f_saveTaskGeneral(task_ind, task_data);
    },
    f_saveTask: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      OrganizationService.save_task(this.wisdom_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.show_wisdom_data_modal = false;
            if (this.d_taskList[this.selected_task_index] && resp.data.result.id === this.d_taskList[this.selected_task_index].id) {
              this.d_taskList[this.selected_task_index] = resp.data.result;
            }
            this.$forceUpdate();
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_editTask: function (task_ind, type = 'edit_selected_item') {
      this.selected_task_index = task_ind;
      this.d_taskSaveMode = type;
      this.data_type = 'wdm17';
      this.wisdom_data = JSON.parse(JSON.stringify(this.d_taskList[task_ind]));
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        if (!this.wisdom_data['data'][this.option_data[this.data_type].param_group.param_order[i]]) {
          this.wisdom_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
        }
      }
      this.show_wisdom_data_modal = true;
    },
    f_addNewComment: function () {
      if (!this.d_newTaskComment) {
        alert('Lütfen yorum alanına birşeyler yazınız.');
        return;
      }
      if (!this.d_selectedTaskForComment.data.general.comment) {
        this.d_selectedTaskForComment.data.general.comment = { 'list': [] };
      }
      let x = {
        'who_saw': {
          'list': []
        },
        'message': {
          'val': this.d_newTaskComment
        },
        'comment_datetime': {
          'val': 'new'
        },
        'who_commented': {
          'val': {
            'label': this.user.first_name + ' ' + this.user.last_name,
            'value': this.user.username
          }
        }
      };
      this.d_selectedTaskForComment.data.general.comment.list.push(JSON.parse(JSON.stringify(x)));
      let prepare_seen_data = {
        'user_saw': {
          'val': {
            'value': this.user.username,
            'label': this.user.first_name + ' ' + this.user.last_name
          }
        },
        'seen_datetime': {
          'val': ''
        }
      };
      this.d_selectedTaskForComment.data.general.comment.list[this.d_selectedTaskForComment.data.general.comment.list.length - 1].who_saw.list.push(prepare_seen_data);
      OrganizationService.save_task(this.d_selectedTaskForComment)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_taskList[this.selected_task_index] = resp.data.result;
            this.d_newTaskComment = '';
            this.$forceUpdate();
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_calculateCountOfSeenComments: function (task) {
      if (task.data && task.data.general && task.data.general.comment && task.data.general.comment.list) {
        let count = 0;
        for (let c in task.data.general.comment.list) {
          if (task.data.general.comment.list[c].who_saw && task.data.general.comment.list[c].who_saw.list) {
            for (let w in task.data.general.comment.list[c].who_saw.list) {
              if (task.data.general.comment.list[c].who_saw.list[w].user_saw.val && task.data.general.comment.list[c].who_saw.list[w].user_saw.val.value === this.user.username) {
                count += 1;
              }
            }
          }
        }
        return count.toString() + ' / ' + task.data.general.comment.list.length.toString();
      } else {
        return '0 / 0';
      }
    },
    DateFormatWithTime: function (date) {
      return moment(date, 'YYYY-MM-DDThh:mm:ss').format('DD/MM/YYYY HH:mm');
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    f_calculateStatusStyle: function (status_val) {
      if (status_val === 'completed') {
        return 'background: #28a745; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'cancelled') {
        return 'background: #f7959e; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'assigned') {
        return 'background: #f3ff00; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'not_assigned') {
        return 'background: #a3afb6; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'recommended') {
        return 'background: #e08be7; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'refused') {
        return 'background: #c1a4fc; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else if (status_val === 'accepted') {
        return 'background: #01ffe4; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      } else {
        return 'background: orange; border-radius: 3px; border: solid 1px #c6cfd4; text-align: center; padding: 2px; margin: 2px; font-size: 10px; width: 100%; white-space: nowrap;';
      }
    },
    f_taskStatusThis: function (status_list, task_data) {
      try {
        if (status_list.indexOf(task_data.data.general.status.val.value) !== -1) {
          return true;
        }
      } catch (err) {
        return false;
      }
    },
    f_getIndexListAsString: function (index_list) {
      let str = '';
      for (let i in index_list) {
        let x = index_list[i].split(',');
        for (let k in x) {
          str += (parseInt(x[k]) + 1).toString();
          str += '.';
        }
        if (parseInt(i) !== index_list.length - 1) {
          str += ' - ';
        }
      }
      return str;
    },
    f_controlOnlyMyPermission: function (task, perm) {
      if (task['organization_data']) {
        for (let i in task['organization_data']['perms_and_index_list']) {
          if (task['organization_data']['perms_and_index_list'][i].perms.indexOf(perm) !== -1) {
            return true;
          }
        }
      }
      return false;
    },
    f_whoCanSeeThisTask: function () {
      this.d_whoCanSeeThisTask = [];
      let query = 'task_id=organization_' + this.d_taskList[this.selected_task_index].organization_data.id + '_task_' + this.d_taskList[this.selected_task_index].id;
      OrganizationService.who_can_see_this_task(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_whoCanSeeThisTask = resp.data.result;
          } else {
            alert('error ' + resp.data.message);
          }
        });
    },
    f_showComments: function (task_ind, type) {
      this.d_newTaskComment = '';
      this.selected_task_list_type = type;
      this.selected_task_index = task_ind;
      // this.f_whoCanSeeThisTask();
      this.d_selectedTaskForComment = this.d_taskList[task_ind];
      let task = this.d_selectedTaskForComment;
      let task_updated = false;
      let comment_users = [];
      let user_pic_keys = [];
      if (task.data && task.data.general && task.data.general.comment && task.data.general.comment.list) {
        for (let c in task.data.general.comment.list) {
          let user_value = task.data.general.comment.list[c].who_commented.val.value;
          if (!this.d_allOrganizationUsers[user_value] || (this.d_allOrganizationUsers[user_value] && !this.d_allOrganizationUsers[user_value].pic)) {
            comment_users.push(user_value);
            user_pic_keys.push('user_' + user_value + '_picture');
          }
          let comment_seen = false;
          if (task.data.general.comment.list[c].who_saw && task.data.general.comment.list[c].who_saw.list) {
            for (let w in task.data.general.comment.list[c].who_saw.list) {
              if (task.data.general.comment.list[c].who_saw.list[w].user_saw.val && task.data.general.comment.list[c].who_saw.list[w].user_saw.val.value === this.user.username) {
                comment_seen = true;
              }
            }
          }
          if (!comment_seen) {
            task_updated = true;
            if (!task.data.general.comment.list[c].who_saw) {
              task.data.general.comment.list[c].who_saw = { 'list': [] };
            }
            let prepare_seen_data = {
              'user_saw': {
                'val': {
                  'value': this.user.username,
                  'label': this.user.first_name + ' ' + this.user.last_name
                }
              },
              'seen_datetime': {
                'val': ''
              }
            }
            task.data.general.comment.list[c].who_saw.list.push(prepare_seen_data);
          }
        }
      }
      if (task_updated) {
        OrganizationService.save_task(this.d_selectedTaskForComment)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_taskList[task_ind] = resp.data.result;
            } else {
              alert('error ' + resp.data.message);
            }
          });
      }
      if (user_pic_keys.length > 0) {
        GlobalService.get_multiple_cb_documents({ 'key_list': user_pic_keys, 'bucket': 'uploads' })
          .then(resp => {
            if (resp.data.status === 'success') {
              for (let i in resp.data.result) {
                if (!this.d_allOrganizationUsers[resp.data.result[i].username]) {
                  this.d_allOrganizationUsers[resp.data.result[i].username] = { 'pic': '' };
                }
                this.d_allOrganizationUsers[resp.data.result[i].username].pic = resp.data.result[i].picture;
              }
              this.d_showTaskCommentsModal = true;
              this.addEventListenerToWriting();
            } else {
              console.log('error:  ' + resp.data.message);
            }
          });
      } else {
        this.d_showTaskCommentsModal = true;
        this.addEventListenerToWriting();
      }
    },
    addEventListenerToWriting: function () {
      const handler = (evt) => {
        setTimeout(function () {
          d_newTaskCommentKeyLastLocation = parseInt(evt.srcElement.selectionStart);
          // console.log('handler: ', d_newTaskCommentKeyLastLocation);
        }, 0);
      };
      setTimeout(function () {
        var div = document.getElementById("write-comment");
        div.addEventListener("keydown", handler);
      }, 2);
    },
    f_isActiveUserEqualOrUpperOfTaskLayerList: function (task, can_use_me = false, team_member = false) {
      // wca_start
      // task.index_list => ['0,1,2', '4,2,1']; if a task assigned to at least one layer, we have this parameter
      // wca_end
      if (!task.index_list) {
        return true;
      }
      let task_layer_list = task.index_list;
      if (!task_layer_list || (task_layer_list && task_layer_list.length === 0)) {
        try {
          if (task.created_by === this.user.username && task.data.general.status.val.value === 'not_assigned') {
            return true;
          }
        } catch (err) {
          return false;
        }
        return false;
      }
      let my_item_index_list = [];
      if (task['organization_data']) {
        for (let i in task['organization_data']['perms_and_index_list']) {
          my_item_index_list.push(task['organization_data']['perms_and_index_list'][i].index);
        }
      }
      if (my_item_index_list.length === 0) {
        return false;
      }
      for (let i in my_item_index_list) {
        let one_back_index = '';
        if (team_member) {
          let arr = my_item_index_list[i].split(',');
          arr.splice(arr.length - 1, 1);
          one_back_index = arr.join(',');
        }
        for (let k in task_layer_list) {
          let layer_index = task_layer_list[i];
          // TODO layer_index split length ile my irem index list split length eşitliğ veyanın içinde kontrol edilecek
          if (layer_index.indexOf(my_item_index_list[i]) === 0 || (team_member && layer_index.indexOf(one_back_index) === 0)) {
            if (!can_use_me) {
              if (my_item_index_list[i] !== layer_index) {
                return true;
              }
            } else {
              return true;
            }
          }
        }
      }
      return false;
    },
    f_getUserListWithDepartments: function () {
      UserService.get_all_users_with_departments()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_userListWithDepartments = resp.data.result;
            this.d_whoCanSeeThisTask = this.d_userListWithDepartments;
          } else {
            console.log(resp.data.message);
          }
        });
    },
    f_getTaskList: function (selected_layer = []) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Organizasyon taskları yükleniyor' } });
      this.d_searchTaskListData.selected_layer = selected_layer;
      this.d_searchTaskListData.username = this.user.username;
      this.d_searchTaskListData.organization_id = this.organization_id;
      this.d_searchTaskListData.pagination = this.d_pagination;
      this.d_searchTaskListData.search_text = this.d_searchText;
      if (this.p_getSpecificTasks && this.p_getSpecificTasks.status) {
        OrganizationService.get_specific_tasks(this.p_getSpecificTasks)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_taskList = resp.data.result.list;
              this.d_tmpTaskList = resp.data.result.list;
              this.d_taskListCount = resp.data.result.count;
            } else {
              alert('error ' + resp.data.message);
            }
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          });
      } else {
        OrganizationService.get_task_list(this.d_searchTaskListData)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.d_taskList = resp.data.result.list;
              this.d_tmpTaskList = resp.data.result.list;
              this.d_taskListCount = resp.data.result.count;
            } else {
              alert('error ' + resp.data.message);
            }
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          });
      }
    },
    f_getIndexListOfSubLayers: function (list_data, layer_index_list) {
      for (let i in list_data) {
        layer_index_list.push(list_data[i].index);
        if (list_data[i].list && list_data[i].list.length > 0) {
          this.f_getIndexListOfSubLayers(list_data[i].list, layer_index_list);
        }
      }
    },
    f_getTaskListWithWatch: function () {
      if (this.selected_organization_item && this.selected_organization_item.index && this.selected_organization_item.isTitle && this.selected_organization_item.isTitle === 'no' && this.selected_organization_item.user && this.d_searchTaskListData.user_type === 'selected') {
        // wca_start
        // this.selected_organization_item.index => '0,0,4'
        // wca_end
        this.f_getTaskList([this.selected_organization_item.index]);
      } else {
        if (this.d_searchTaskListData.user_type === 'selected_and_sub_layers') {
          let layer_index_list = [];
          layer_index_list.push(this.selected_organization_item.index);
          this.f_getIndexListOfSubLayers(this.selected_organization_item.list, layer_index_list);
          this.f_getTaskList(layer_index_list);
        } else {
          this.f_getTaskList();
        }
      }
    }
  },
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_createWdm21ForTaskConfirmed') {
          if (this.StoreModal.data.confirm) {
            this.f_createWdm21ForTaskConfirmed(this.StoreModal.data.arguments[0]);
          }
        }
      }
    },
    'watch_task_list.get_task_list': function () {
      this.f_getTaskListWithWatch();
    },
    'd_newTaskComment': function () {
      this.d_filteredWhoCanSeeUsers = [];
      if (!this.d_filterAbility) {
        return;
      }
      if (this.d_newTaskComment.length > 1 && (this.d_newTaskComment.indexOf('@') !== -1 || this.d_newTaskComment.indexOf('#') !== -1)) {
        let det = false;
        let username = '';
        // console.log(d_newTaskCommentKeyLastLocation);
        for (let i = d_newTaskCommentKeyLastLocation; i >= 0; i--) {
          if (this.d_newTaskComment[i] === ' ') {
            break;
          }
          if (this.d_newTaskComment[i] === '@' || this.d_newTaskComment[i] === '#') {
            det = true;
            break;
          }
          username += this.d_newTaskComment[i];
        }
        // console.log('username: ', username);
        if (det) {
          username = username.split('');
          username = username.reverse();
          username = username.join('');
          for (let i in this.d_whoCanSeeThisTask) {
            if (this.d_whoCanSeeThisTask[i].value.toLocaleLowerCase().indexOf(username.toLocaleLowerCase()) !== -1) {
              this.d_filteredWhoCanSeeUsers.push(this.d_whoCanSeeThisTask[i]);
            }
          }
        }
      }
    },
    'd_searchText': function () {
      if (this.d_canGetTaskListWithWatch) {
        let tmp_text = this.d_searchText;
        setTimeout(function () {
          if (tmp_text === this.d_searchText) {
            this.f_getTaskListWithWatch();
          }
        }.bind(this), 500)
      }
    },
    'd_searchTaskListData.priority': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_searchTaskListData.organization_list': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_searchTaskListData.state_list': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_searchTaskListData.task_type_list': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_searchTaskListData.tree_filter': function () {
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_searchTaskListData.user_type': function () {
      this.d_searchTaskListData.active_user_index_list = JSON.parse(JSON.stringify(this.my_item_index_list));
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    },
    'd_pagination.current': function () {
      this.d_pagination.start = (this.d_pagination.current - 1) * this.d_pagination.perpage;
      this.d_pagination.end = (this.d_pagination.perpage * this.d_pagination.current) - 1;
      if (this.d_canGetTaskListWithWatch) {
        this.f_getTaskListWithWatch();
      }
    }
  },
  components: {
    Datepicker,
    Modal,
    TreeList,
    WisdomDataModal,
    WisdomDataShow
  }
};

</script>

<style type="text/css">
#messages-list::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

.wisdom-task {
  padding: 5px;
  margin: 0px;
  margin-top: 5px;
  border-bottom: solid 1px #81b290;
  box-shadow: -2px -2px 10px;
  border-radius: 3px;
}

.wisdom-task:hover {
  background: #f0f8ff;
}

#messages-list::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#messages-list::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.task-comment {
  border: solid 1px #c6cfd4;
  background: white;
  border-radius: 3px;
  padding: 3px;
  font-size: 10px;
  cursor: pointer;
  float: right;
}

.task-comment:hover {
  background: #9ff5ff;
}

.auto-complete-row {
  margin: 0px;
  cursor: pointer;
  border-bottom: solid 1px black;
  border-radius: 3px;
  box-shadow: -1px -1px 2px 2px;
  padding: 3px;
  margin-bottom: 5px;
}

.auto-complete-row:hover {
  background: #bfe9bf;
}

</style>

